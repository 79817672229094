import './bootstrap';
import '../css/app.css';
import './Partials/icon';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Tooltip, Dropdown, Menu } from 'floating-vue';
import { VueTiptapKatex } from 'vue3-tiptap-katex';
import { route, ZiggyVue } from 'ziggy-js';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Sentry from '@sentry/vue';

import 'floating-vue/dist/style.css';
import 'vue-select/dist/vue-select.css';
import {
  faCashRegister,
  faLocationPin,
  faIdCardClip,
  faWallet,
  faComments,
  faPlus,
  faArrowUpWideShort,
  faTable,
  faCrown,
  faArrowsUpDownLeftRight,
  faFileArrowUp,
  faBoxArchive,
  faO,
  faCircleDot,
  faChevronDown,
  faChevronUp,
  faX,
  faFilterCircleXmark,
  faCalendarDays,
  faUser,
  faClock,
  faPencil,
  faTrashCan,
  faCircleNotch,
  faCommentDollar,
  faCartShopping,
  faFileContract,
  faHourglassHalf,
  faMessage,
  faBuilding,
  faTags,
  faUserPlus,
  faCalendarPlus,
  faFlagCheckered,
  faLocationDot,
  faHandHoldingDollar,
  faArrowDown,
  faPlay,
  faHandshakeSimple,
  faCheck,
  faSquare,
  faTag,
  faSackDollar,
  faPiggyBank,
  faRotateRight,
  faThumbTack,
  faCopy,
  faClipboard,
  faPrint,
  faCaretRight,
  faBan,
  faUpload,
  faBox,
  faBuildingColumns,
  faSkull,
  faCloudArrowDown,
  faShare,
  faFileInvoiceDollar,
  faFileShield,
  faFileInvoice,
  faReceipt,
  faFolderOpen,
  faMinus,
  faChevronRight,
  faChevronLeft,
  faUserNurse,
  faSquareCheck,
  faDownload,
  faArrowLeft,
  faUserClock,
  faTrashArrowUp,
  faInfo,
  faLandMineOn,
  faFileCircleQuestion,
  faThumbsUp,
  faSignature,
  faFileExcel,
  faForwardStep,
  faEye,
  faComputer,
} from '@fortawesome/free-solid-svg-icons';
// Vue FilePond

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';
library.add(
  faComputer,
  faEye,
  faForwardStep,
  faFileExcel,
  faSignature,
  faThumbsUp,
  faFileCircleQuestion,
  faLandMineOn,
  faInfo,
  faTrashArrowUp,
  faUserClock,
  faArrowLeft,
  faDownload,
  faSquareCheck,
  faUserNurse,
  faChevronLeft,
  faChevronRight,
  faMinus,
  faFolderOpen,
  faReceipt,
  faFileInvoice,
  faFileShield,
  faFileInvoiceDollar,
  faShare,
  faCloudArrowDown,
  faSkull,
  faBuildingColumns,
  faUpload,
  faBan,
  faCaretRight,
  faPrint,
  faClipboard,
  faCopy,
  faThumbTack,
  faRotateRight,
  faPiggyBank,
  faSackDollar,
  faLocationPin,
  faIdCardClip,
  faWallet,
  faComments,
  faBox,
  faTag,
  faPlus,
  faArrowUpWideShort,
  faTable,
  faCrown,
  faArrowsUpDownLeftRight,
  faFileArrowUp,
  faBoxArchive,
  faSquare,
  faCheck,
  faHandshakeSimple,
  faPlay,
  faO,
  faArrowDown,
  faCircleDot,
  faChevronDown,
  faChevronUp,
  faX,
  faFilterCircleXmark,
  faCalendarDays,
  faUser,
  faClock,
  faPencil,
  faTrashCan,
  faCircleNotch,
  faCommentDollar,
  faCartShopping,
  faFileContract,
  faHourglassHalf,
  faMessage,
  faHandHoldingDollar,
  faFlagCheckered,
  faLocationDot,
  faBuilding,
  faCalendarPlus,
  faUserPlus,
  faTags,
  faCashRegister,
);
createInertiaApp({
  title: (title) => (title ? `${title} — ${appName}` : appName),
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  progress: {
    color: '#f00',
    showSpinner: true,
  },
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) })
      .use(plugin)
      .component('v-tooltip', Tooltip)
      .component('v-dropdown', Dropdown)
      .component('v-menu', Menu)
      .component('font-awesome-icon', FontAwesomeIcon)
      .component('vue-tiptap-katex', VueTiptapKatex)
      .use(ZiggyVue)
      .mount(el);

    if (import.meta.env.VITE_VUE_SENTRY_DSN) {
      Sentry.init({
        app,
        dsn: import.meta.env.VITE_VUE_SENTRY_DSN,
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        tracesSampleRate: 1.0,
        tracePropagationTargets: ['localhost'],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    }

    return app;
  },
});
